import { useEffect, useState } from "react";
import basket from "@assets/media/images/basket.svg";
import chefs from "@assets/media/images/chefs-hat.svg";
import hotcase from "@assets/media/images/hotcase.svg";
import { SpringAnimation } from "@utils/constant/constant";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import './Featureblock.scss';
import { getBottomData } from "@/services/CommonService";
import config from "@/config";

function BottomAnimation() {
  const width = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const mobileView = width < 575 ? true : false;
  const [inViewPort, setInViewPort] = useState<Boolean>(false);
  const [a1, setA1] = useState({
    x: SpringAnimation.INITIAL_X,
    y: SpringAnimation.INITIAL_Y,
    show: false,
    img: '',
    description: '',
  });
  const [a2, setA2] = useState({
    x: SpringAnimation.INITIAL_X,
    y: SpringAnimation.INITIAL_Y,
    show: false,
    img: '',
    description: '',
  });
  const [a3, setA3] = useState({
    x: SpringAnimation.INITIAL_X,
    y: SpringAnimation.INITIAL_Y,
    show: false,
    img: '',
    description: '',
  });

  const getContent = async() =>{
    const resp:any = await getBottomData(Number(localStorage.getItem('restaurant_id')))
    setA1((prev) => ({
      ...prev,
      img: resp?.data?.data?.image1,
      description: resp?.data?.data?.content1,
    }));
    setA2((prev) => ({
      ...prev,
      img: resp?.data?.data?.image2,
      description: resp?.data?.data?.content2,
    }));
    setA3((prev) => ({
      ...prev,
      img: resp?.data?.data?.image3,
      description: resp?.data?.data?.content3,
    }));
  }

  useEffect(() => {
    getContent();
    if (mobileView) {
      setA1((prev) => ({
        ...prev,
        x: -130,
        y: 0,
      }));
      setA2((prev) => ({
        ...prev,
        x: 130,
        y: 0,
      }));
      setA3((prev) => ({
        ...prev,
        x: -130,
        y: 0,
      }));
    }
  }, [mobileView]);

  const inView = async (inView: boolean) => {
    if (inView && !inViewPort) {
      setInViewPort(true);
      await new Promise((r) => setTimeout(r, 500));
      setA1((prev) => ({
        ...prev,
        x: SpringAnimation.AFTER_X,
        y: SpringAnimation.AFTER_Y,
        show: true,
      }));
      await new Promise((r) => setTimeout(r, 500));
      setA2((prev) => ({
        ...prev,
        x: SpringAnimation.AFTER_X,
        y: SpringAnimation.AFTER_Y,
        show: true,
      }));
      await new Promise((r) => setTimeout(r, 500));
      setA3((prev) => ({
        ...prev,
        x: SpringAnimation.AFTER_X,
        y: SpringAnimation.AFTER_Y,
        show: true,
      }));
    }
  };
  const inViewMobile = async (inView: boolean, type: string) => {
    if (inView) {
      await new Promise((r) => setTimeout(r, 500));
      switch (type) {
        case "a1":
          setA1((prev) => ({
            ...prev,
            x: SpringAnimation.AFTER_X,
            y: SpringAnimation.AFTER_Y,
            show: true,
          }));
          break;
        case "a2":
          setA2((prev) => ({
            ...prev,
            x: SpringAnimation.AFTER_X,
            y: SpringAnimation.AFTER_Y,
            show: true,
          }));
          break;
        case "a3":
          setA3((prev) => ({
            ...prev,
            x: SpringAnimation.AFTER_X,
            y: SpringAnimation.AFTER_Y,
            show: true,
          }));
          break;
      }
    }
  };

  const animateIcons = (props: any) => {
    return (
      <div className="cell-4">
        <div className="feature-item">
          { props.img.length ? <motion.div
            animate={{ x: props.x, y: props.y }}
            transition={{ type: "spring" }}
          >
            <figure
              style={{
                visibility: props.show ? "visible" : "hidden",
              }}
            >
              <img src={`${config.web_content}${props.img}`} alt="basket" />
            </figure>
          </motion.div> : '' }

          <h4 dangerouslySetInnerHTML={{ __html: props.description }}>

          </h4>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className={`feature-block ${(!a1.img || !a2.img || !a3.img) ? 'feature-noimg' : ''}`}>
        {!mobileView ? (
          <InView className="cs-container" onChange={(e) => inView(e)}>
            <div className="cs-row" id="cs-container-facility">
              {animateIcons(a1)}
              {animateIcons(a2)}
              {animateIcons(a3)}
            </div>
          </InView>
        ) : (
          <div className="cs-container">
            <div className="cs-row" id="cs-container-facility">
              <InView onChange={(e) => inViewMobile(e, "a1")}>
                {animateIcons(a1)}
              </InView>
              <InView onChange={(e) => inViewMobile(e, "a2")}>
                {animateIcons(a2)}
              </InView>
              <InView onChange={(e) => inViewMobile(e, "a3")}>
                {animateIcons(a3)}
              </InView>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default BottomAnimation;
