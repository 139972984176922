import "./Loader.scss"
import loaderGif from "@assets/media/images/loader.gif";
function Loader({load}: any){    
    return(
        <>
            <div className={`cs-loader ${load ? 'show' : ''}`}>
                <div className="cs-loader__media">
                    <img loading="lazy" src={loaderGif} alt="" />
                </div>
                    <div className="waviy">
                        <span>L</span>
                        <span>O</span>
                        <span>A</span>
                        <span>D</span>
                        <span>I</span>
                        <span>N</span>
                        <span>G</span>
                    </div>
            </div>
        </>
    )
}
export default Loader;