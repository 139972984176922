import moment from "moment";

export const LazyLoader = (props: any) => {
  return (
    <>
      <div className="loading-wrapper">LOADING</div>
    </>
  );
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const phoneNumberMask = "###-###-####";

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }
  return null;
}

export const convertStringToTime = (time: string) => {
  const today = (new Date()).toISOString();
  const nowDate = today.split('T')[0];
  const target = new Date(nowDate + 'T' + time);
  return moment(target.getTime()).format('LT');
}