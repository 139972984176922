import { PopupType } from '@/@types/PopupModel';
import { toggleLocation } from '@/store/Reducer/Event/EventReducer';
import { useAppDispatch } from '@/store/StoreHooks';
import { useEffect } from 'react';
interface routeGuardType {
  component: React.FC,
  callback: any
}
export default function RouteGuard({ component, callback }: routeGuardType) {
  const restaurantId = localStorage.getItem('restaurant_id');
  const dis = useAppDispatch();
  
  useEffect(() => {    
    if (!restaurantId) {
      dis(toggleLocation({ [PopupType.LOCATION]: false}));
      callback(true);
    }
    //eslint-disable-next-line
  }, [restaurantId]);

  return <>{ component }</>;
}