import { propsType } from "@/@types/PopupModel";
import { useAppSelector } from "@/store/StoreHooks";
import "./Popup.scss";
import { PopupType } from "@/@types/PopupModel";

function Popup({ type, children, close }: propsType) {
  // const shakeIt = ()=>{
  //   document.querySelector('.cs-popup')?.classList.remove('scaleShake');
  //   setTimeout(()=>{
  //     document.querySelector('.cs-popup')?.classList.add('scaleShake');
  //   },100);
  // }
  const showMenu: any = useAppSelector((val) => val.eventsData);
  return (
    <>
    {/*
    classes 
            location-popup
            product-popup
    with class cs-popup
    */}
      <div className={`cs-popup ${type === PopupType.LOCATION ? `location-popup ${!showMenu.location ? '' : 'hide'}` : `product-popup ${showMenu.menu ? 'show' : ''}`}`}>
        <div className="popup-container">
          {/* <span className="close" onClick={() => close()}>
            <i className="cs-icon icon-close"></i>
          </span> */}
          <div className="popup-body">{children}</div>
        </div>
      {/* <div className="cs-popup-overlay" onClick={()=>{
        shakeIt();}}></div> */}
      </div>
    </>
  );
}
export default Popup;
