import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface stateType {
  restaurant?: any,
  phone?: any,
  closing_time?: any,
  opening_time?: any
}
const initialState: stateType = {
  restaurant: {},
}

export const SelectedRestaurant = createSlice({
  name: 'SelectedRestaurant',
  initialState,
  reducers: {
    setSelectedRestaurant: (state, val: PayloadAction<stateType>) => {
      state.restaurant = val.payload.restaurant;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSelectedRestaurant } = SelectedRestaurant.actions
export default SelectedRestaurant.reducer;
