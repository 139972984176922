import axiosApi from "@/core/interceptor/interceptor";
import config from '@/config';
import { IBaseResponse } from "@/@types/ResponseModel";

export function getContent(params: any): Promise<IBaseResponse<any>> {
    return axiosApi.get(`${config.apiUrl}misc/main-slider`, {params});
}

export function getSpecialRecipes(id: number): Promise<IBaseResponse<any>> {
    return axiosApi.get(`${config.apiUrl}misc/special-recipes/${id}`);
}

export function getActiveLocation(): Promise<IBaseResponse<any>> {
    return axiosApi.get(`${config.apiUrl}misc/active-location`);
}

export function submitContactUs(data: any): Promise<IBaseResponse<any>> {
    return axiosApi.post(`${config.apiUrl}misc/contact-us`, data);
}

export function getRecipeMenuCategories(id: number): Promise<IBaseResponse<any>> {
    return axiosApi.post(`${config.apiUrl}misc/menu/${id}`);
}

export function getCurrentRestaurant(id: number): Promise<IBaseResponse<any>> {
    return axiosApi.get(`${config.apiUrl}misc/current-restaurant/${id}`);
}

export function getReviews(): Promise<IBaseResponse<any>> {
    return axiosApi.get(`${config.apiUrl}misc/reviews`);
}

export function getNotifications(id: number): Promise<IBaseResponse<any>> {
    return axiosApi.get(`${config.apiUrl}misc/notification-banner/${id}`);
}

export function getBottomData(id: number): Promise<IBaseResponse<any>> {
    return axiosApi.get(`${config.apiUrl}misc/footer-data/${id}`);
}

export function getCateringeMenu(id: number): Promise<IBaseResponse<any>> {
    return axiosApi.get(`${config.apiUrl}misc/catering/${id}`);
}

export function getContactEmail(id: number): Promise<IBaseResponse<any>> {
    return axiosApi.get(`${config.apiUrl}misc/contact-email/${id}`);
}