const config = {
	apiUrl: `${process.env.REACT_APP_API_URL}`,
	api: `${process.env.REACT_APP_API}`,
	web_content:`${process.env.REACT_APP_IMAGE_URL}/web_content/`,
	image_url: `${process.env.REACT_APP_IMAGE_URL}`,
	recipes:`${process.env.REACT_APP_IMAGE_URL}/recipes/`,
	catering:`${process.env.REACT_APP_IMAGE_URL}/catering/`,
	GOOGLE_KEY: `${process.env.REACT_APP_GOOGLE_KEY}`,
	timezone: 'America/New_york'
};
export default config;