import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface ToggleState {
  menu?: boolean,
  location?: boolean,
}

const initialState: ToggleState = {
    menu: false,
    location: false,
}


export const Events = createSlice({
  name: 'event',
  initialState,
  reducers: {
    toggleMenu: (state, val: PayloadAction<ToggleState>) => {
      state.menu = val.payload.menu;
    },
    toggleLocation: (state, val: PayloadAction<ToggleState>) => {
      state.location = val.payload.location;
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleMenu , toggleLocation} = Events.actions
export default Events.reducer;
