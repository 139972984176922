import "./Notifications.scss";
import Marquee from "react-fast-marquee";
import { useEffect, useState } from "react";
import { getNotifications } from "@/services/CommonService";
interface callbackFunc {
    (cb: boolean): void;
}
interface propsType {
    show: callbackFunc
}
function TopbarNotification(show: propsType){
    const restaurantId: number = Number(localStorage.getItem('restaurant_id'))
    const [marqueeContent, setMarqueeContent] = useState([]);
    const getNotis = async (id: number) => {
        const res = await getNotifications(id);
        if (res?.data?.data) {
            const resp = (res.data.data).filter((el: any) => el.type === 'banner')
            show.show(resp.length ? true : false)
            setMarqueeContent(resp.filter((el: any) => el.type === 'banner'));
        }
    }

    useEffect(() => {
        if (restaurantId) {
            getNotis(restaurantId);
        }
    }, [restaurantId])
    return(
        <>
        {marqueeContent && marqueeContent.length ?
        (<div className="topbarNotifi">
            <Marquee gradient={false} speed={40}>
                {
                    marqueeContent.map((element: any, idx: number) => (
                        <p key={element?.id} dangerouslySetInnerHTML={{__html: element.content}}></p>
                    ))
                }
            </Marquee>
        </div>) : " "}
        </>
    )
}
export default TopbarNotification;