import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Define a type for the slice state
interface UserState {
  id?: number,
  first_name: string,
  last_name: string
}

const initialState: UserState = {
  first_name: '',
  last_name: '',
}

export const userDetails = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    userData: (state, val: PayloadAction<UserState>) => {
        console.log('state', val);
      // eslint-disable-next-line no-self-assign
      state = val.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { userData } = userDetails.actions
export default userDetails.reducer;
