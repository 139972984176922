import Popup from '../Popup'
import { PopupType } from '@/@types/PopupModel'
import cardImg from '@assets/media/images/logo.png'
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks'
import { toggleLocation } from '@/store/Reducer/Event/EventReducer'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setSelectedRestaurant } from '@/store/Reducer/SelectedRestaurant/SelectedRestaurantReducer'
import { getActiveLocation } from '@/services/CommonService'
import { setLocations } from '@/store/Reducer/Menu/MenuReducer'

function LocationPopup (props: any) {
  const navigate = useNavigate()
  const dis = useAppDispatch()
  const preFetch = useRef<Boolean>(false)
  const showLocation = useAppSelector(val => val.eventsData.location)
  const hideShow = (): void => {
    dis(toggleLocation({ [PopupType.LOCATION]: !showLocation }))
  }
  const locationList = useAppSelector(val => val.menuData.location)
  const [active, setActive] = useState<number>(-1)
  let [restaurantData, setRestaurantData] = useState<any>('')

  const redirectTo = () => {
    if (active !== -1 && locationList && locationList[active]) {
      if (restaurantData && restaurantData.timings) {
        setLocalStorage(restaurantData)
      }
    }
  }

  const getActiveLocations = async () => {
    try {
      const res = await getActiveLocation()
      if (res?.data?.data) {
        if (res.data.data.length === 1) {
          let resp = res.data.data[0]
          setLocalStorage(resp);
        }
        dis(setLocations({ location: res?.data?.data }))
      }
    } catch (e) {
      console.log('')
    }
  }

  const setLocalStorage = (restaurantData: any) => {
    const today = new Date()
    const time = restaurantData?.timings[today.getDay()]?.closing_time
    restaurantData = { ...restaurantData, closing_time: time }
    window.localStorage.setItem('restaurant_id', restaurantData.id)
    window.localStorage.setItem('closing_time', time)
    dis(
      setSelectedRestaurant({
        restaurant: restaurantData,
        closing_time: time
      })
    )
    dis(toggleLocation({ [PopupType.MENU]: false }))
    props?.callback(false)
    hideShow()
    navigate(`/`)
  }

  useEffect(() => {
    if (!preFetch.current) {
      preFetch.current = true
      getActiveLocations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Popup type={PopupType.LOCATION} close={hideShow}>
      <div className='popup-contents'>
        <div className='common-header-ui text-center'>
          <h3 className='cs-popup-title text-title'>
            Select <span>location</span>
          </h3>
        </div>
        <div className='flex flex--3 flex--mob--2 flex--sm-mob--1 location-cards justify-center'>
          {locationList &&
            locationList?.length > 0 &&
            locationList?.map((val, k) => (
              <div
                className={`flex__item`}
                key={k}
                onClick={() => {
                  setActive(k)
                  setRestaurantData(val)
                }}
              >
                <div className={`card ${active === k ? 'active' : ''}`}>
                  <div className='card-mark'>
                    <i className='cs-icon icon-mark'></i>
                  </div>
                  <div className='card-img'>
                    <img src={cardImg} alt='' />
                  </div>
                  <div className='card-title-box'>
                    <h3 className='card-title'>
                      <span>
                        {val.address}, {val.city}, {val?.state?.abbreviation},{' '}
                        {val.zip}, USA
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className='buttons-group justify-center'>
          <button className='btn btn--pri' onClick={() => redirectTo()}>
            Continue
          </button>
        </div>
      </div>
    </Popup>
  )
}

export default LocationPopup
