import { useEffect } from "react";
import "./App.scss";
import Pages from "./components/routing/Router";
import { useAppSelector, useAppDispatch } from "./store/StoreHooks";
import { getActiveLocation, getCurrentRestaurant } from "./services/CommonService";
import { setSelectedRestaurant } from "./store/Reducer/SelectedRestaurant/SelectedRestaurantReducer";
import { setLocations } from "./store/Reducer/Menu/MenuReducer";
import Loader from "./components/common/Loader/Loader";

function App() {
  const dis = useAppDispatch();
  const restaurantId = Number(localStorage.getItem('restaurant_id'));
  const loader = useAppSelector((val) => val.loader);
  useEffect(() => {
    if (restaurantId) {
      (async() => {
        const res = await getCurrentRestaurant(restaurantId);
        const locations = await getActiveLocation();
        if (!res?.data?.data?.is_public) {
          localStorage.clear()
        }
        if (res?.data?.data && res?.data?.data?.is_public) {
          const data: any = res.data.data;
          data.timings = JSON.parse(data.timings)
          let ctime = ''
          let stime = ''
          if (data && data.timings) {
            const today = new Date();
            ctime = data?.timings[today.getDay()]?.closing_time;
            stime = data?.timings[today.getDay()]?.opening_time
            data.closing_time = ctime ? ctime : ''
            data.opening_time = stime ? stime : ''
          }
          const closingTime = localStorage.getItem('closing_time')
          const openingTime = localStorage.getItem('opening_time')
          if (!closingTime) {
            localStorage.setItem('closing_time', ctime)
          }
          if (!openingTime) {
            localStorage.setItem('opening_time', stime)
          }
          dis(setSelectedRestaurant({ restaurant: data, closing_time: ctime, opening_time: stime }));
        }
        if (locations?.data?.data) {
          dis(setLocations({ location: locations?.data?.data }));
        }
      })();
    }
    // eslint-disable-next-line
  }, [restaurantId])

  return (
    <>
    <Pages/>
    <Loader load={loader}  />
    </>
  );
}

export default App;
