import { useCallback, useEffect, useState } from 'react'
import './Header.scss'
import mainLogo from '@assets/media/images/logo.png'
import phoneIcon from '@assets/media/images/phone.svg'
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks'
import { Link } from 'react-router-dom'
import LocationsPopup from '@components/popup/Menu/Locations'
import { formatPhoneNumber } from '@/utils/helper'
import { convertStringToTime } from '@/utils/helper'
import PreLoader from '@/components/layout/PreLoader/PreLoader'
import { setSelectedRestaurant } from '@/store/Reducer/SelectedRestaurant/SelectedRestaurantReducer'
import TopbarNotification from '@components/common/Notifications/TopbarNotification'
import PopupBanner from '@/components/common/Notifications/PopupBanner'
import moment from 'moment-timezone';
import config from '@/config';
import RightArrow from '@assets/media/images/arrow-right-icon.svg'

function Header (props: any) {
  const dis = useAppDispatch();
  const [toggle, toggleClass] = useState(false)
  const showLocation = useAppSelector(val => val.eventsData.location)
  const showMenu = useAppSelector(val => val.eventsData.menu)
  const locations = useAppSelector(val => val.menuData.location)
  const defRestaurant = useAppSelector(val => val.selectedRestaurant)
  const [defaultrestaurant, setDefaultRestaurant] = useState<any>({
    phone: '',
    closing_time: '',
    address: '',
    city: ''
  })
  const [resLocations, setResLocations] = useState<any>()
  const [toggleDropdown, setToggleDropDown] = useState<boolean>(false);
  const [showTopNoti, setShowTopNoti] = useState<boolean>();
  const [timingInterval, setTimingInterval] = useState<any>(null);
  useEffect(() => {
    if (showMenu) {
      document.body.classList.add('hide-scroll');
      document.getElementsByTagName('html')[0].classList.add('hide-scroll');
    } else {
      document.body.classList.remove('hide-scroll');
      document.getElementsByTagName('html')[0].classList.remove('hide-scroll');
    }
  }, [showMenu])

  useEffect(() => {
    // var overlay = document.getElementById("preloader");
    document.getElementsByTagName('html')[0].classList.add('hide-scroll')
    if (document.readyState === 'complete') {
      document.body.classList.add('loaded')
      document.getElementsByTagName('html')[0].classList.remove('hide-scroll')
    } else {
      window.addEventListener('load', function () {
        document.body.classList.add('loaded')
        document.getElementsByTagName('html')[0].classList.remove('hide-scroll')
      })
    }
  }, [])

  useEffect(() => {
    return () => {
      if (timingInterval) {
        clearInterval(timingInterval);
      }
    }
  }, [timingInterval])
  
  const compareTime = (opening_time: string, closing_time: string) => {
    let currentTime: any = moment().tz(config.timezone).format("HH:mm");
      const startTime = moment(opening_time, "HH:mm");
      const endTime = moment(closing_time, "HH:mm");
      currentTime = moment(currentTime, "HH:mm");

      let flag = false;
      if (endTime.isBefore(startTime)) {
        const range1 = [startTime, moment("23:59:59", "HH:mm:ss")];
        const range2 = [moment("00:00", "HH:mm"), endTime];
        // Check if the current time is within either of the ranges.
        if (currentTime.isBetween(range1[0], range1[1]) || currentTime.isBetween(range2[0], range2[1])) {
          flag = true;
        }
      } else {
        // If endTime is after startTime, it's a single continuous range.
        if (currentTime.isBetween(startTime, endTime)) {
          flag = true;
        }
      }
      return flag;
  }

  const checkTiming = useCallback((opening_time: string, closing_time: string) => {
    if (opening_time && closing_time) {
      const flag = compareTime(opening_time, closing_time);
      setDefaultRestaurant((prev: any) => ({
        ...prev,
        closing_time: (flag) ? closing_time : '',
      }))
    }
  }, []);
  
  useEffect(() => {
    if (defRestaurant && defRestaurant.restaurant?.city) {
      setDefaultRestaurant((prev: any) => ({
        ...prev,
        phone: defRestaurant?.restaurant?.phone,
        closing_time: (compareTime(defRestaurant?.restaurant?.opening_time, defRestaurant?.restaurant?.closing_time)) ? defRestaurant?.restaurant?.closing_time : '',
        address: defRestaurant?.restaurant?.address,
        city: defRestaurant?.restaurant?.city,
        open_at: defRestaurant?.restaurant?.opening_time,
        close_at: defRestaurant?.restaurant?.closing_time
      }));
      if (!timingInterval && defRestaurant?.restaurant?.opening_time) {
        checkTiming(defRestaurant?.restaurant?.opening_time, defRestaurant?.restaurant?.closing_time);
        const interval = setInterval(() => {
          checkTiming(defRestaurant?.restaurant?.opening_time, defRestaurant?.restaurant?.closing_time);
        }, 10000);
        setTimingInterval(interval);
      }
    }
  }, [checkTiming, defRestaurant, timingInterval]);

  
  
  useEffect(() => {
    setResLocations(locations)
  }, [locations])

  const handleLocationChange = (data: any) => {
    if (data) {
      setToggleDropDown(false);
      const today = new Date();
      const ctime = data?.timings[today.getDay()]?.closing_time;
      const stime = data?.timings[today.getDay()]?.opening_time;
      data = { ...data, closing_time: ctime, opening_time: stime}
      // localStorage.clear();
      window.localStorage.setItem('restaurant_id', data.id);
      window.localStorage.setItem('closing_time', ctime);
      window.localStorage.setItem('opening_time', stime);
      dis(setSelectedRestaurant({ restaurant: data, closing_time: ctime, opening_time: stime }));
      window.location.reload()
    }
  }

  const topNotificationCallback = (cb: boolean) => {
    setShowTopNoti(cb)
  }

  return (
    <>
      <PreLoader />
      {showLocation && <LocationsPopup />}
      <PopupBanner />
      <header className={`App-header ${showTopNoti ? 'with-top-bar' : ''}`}>
      <TopbarNotification show={topNotificationCallback} />
        <div className='main-nav-box'>
          <div className='cs-container'>
            <nav className='main-nav'>
              {resLocations && resLocations.length > 1 ? <div className={`location-selector ${toggleDropdown ? 'active' : ''}`}>
                <div className='location-selector__overlay' onClick={() => setToggleDropDown(false)}></div>
                <div className='location-head-dropdown'>
                  <span className='round-corner left'></span>
                  <span className='round-corner right'></span>
                  <div className='location-selector__selected'>
                    <div className='location-selector__item' onClick={() => setToggleDropDown(prev => !prev)}>
                      <div className='location-selector__item__icon'>
                        <i className='cs-icon icon-location'></i>
                      </div>
                      <div className='location-selector__item__text'>
                        {defaultrestaurant?.address}, {defaultrestaurant?.city}
                      </div>
                      <span className='arrow-icon'>
                        <i className='cs-icon icon-caret-down'></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='location-dropdown-block'>
                  <ul>
                    {resLocations && resLocations.length > 0
                      ? resLocations.map((element: any, idx: number) => (
                          <li key={element.id} onClick={() => handleLocationChange(element)}>
                            <i className='cs-icon icon-location'></i>
                            <label>{element?.address}, {element?.city}</label>
                          </li>
                        ))
                      : ''}
                  </ul>
                </div>
                { 
                  defRestaurant?.restaurant?.redirect_link?.trim() && (
                    <div className="ordernow-btn-wrapper">
                      <a href={defRestaurant.restaurant.redirect_link.trim()} className="ordernow-btn">Order Now</a>
                      <img src={RightArrow} alt="order-icon" />
                    </div>
                  )
                }
              </div> : ''}
              {toggle && (
                <div
                  className={`overlay-hamburger ${toggle ? 'show' : ''}`}
                  onClick={() => toggleClass(!toggle)}
                ></div>
              )}
              <div
                className={`main-nav__list main-nav__list--left ${
                  toggle ? 'open' : ''
                }`}
              >
                {/* NOTE can add class disabled to main-nav__list__item to disable it */}
                <span className='main-nav__list__item'>
                  <Link
                    onClick={() => toggleClass(false)}
                    to={`/menu`}
                  >
                    Menu
                  </Link>
                </span>
                <span className='main-nav__list__item'>
                  <Link onClick={() => toggleClass(false)} to={'/location'}>
                    Locations
                  </Link>
                </span>
                <span className='main-nav__list__item'>
                  <Link onClick={() => toggleClass(false)} to={'/catering'}>
                    Catering
                  </Link>
                </span>
              </div>
              <div className='main-logo-container'>
                <Link to={'/'}>
                  <img src={mainLogo} alt='' />
                </Link>
              </div>
              <div className='main-nav__list main-nav__list--right'>
                {/* <span className="mob-only-menu main-nav__list__item">Menu</span>
              <span className="mob-only-menu main-nav__list__item">Locations</span> */}
                <span className='main-nav__list__item main-nav__list__item-cta not--clickable'>
                  <span>Call us Now:</span>{' '}
                  <span className='nav-call-icon'>
                    <img src={phoneIcon} alt='' />
                  </span>
                  <p className="ph-num">
                    +1{' '}
                    {formatPhoneNumber(defaultrestaurant.phone) ||
                      '123-123-1234'}
                  </p>
                </span>
                {/* <span className="main-nav__list__item btn btn--pri">
                  order now
                </span> */}
                <span className={`main-nav__list__item main-nav__list__item--with-sub with-dot not--clickable ${!defaultrestaurant.closing_time? "rest-closed" : " "}`}>
                  <span className='nav-item-sub'>Open till:</span>{' '}
                  <span className={`online-dot-icon ${!defaultrestaurant.closing_time? "offline":" "}`}></span>{' '}
                  {
                  defaultrestaurant.closing_time
                    ? convertStringToTime(defaultrestaurant.closing_time)
                    : 'Closed'
                    }
                </span>
                <div
                  id='hamburger'
                  className={`hamburger ${toggle ? 'open' : ''}`}
                  onClick={() => toggleClass(!toggle)}
                >
                  <svg viewBox='0 0 100 100'>
                    <path
                      className='line line1'
                      d='M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058'
                    />
                    <path className='line line2' d='M 20,50 H 80' />
                    <path
                      className='line line3'
                      d='M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942'
                    />
                  </svg>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
