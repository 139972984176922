import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Define a type for the slice state

interface stateType {
    location?: any[],
    menus?: any[],
}
const initialState: stateType = {
  location: [],
  menus: [],
}

export const MenuData = createSlice({
  name: 'MenuData',
  initialState,
  reducers: {
    setLocations: (state, val: PayloadAction<stateType>) => {
      state.location = val.payload.location;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLocations } = MenuData.actions
export default MenuData.reducer;
