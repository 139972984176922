import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState: boolean = false;

export const Loader = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoader: (state, val: PayloadAction<boolean>) => {
      return val.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoader } = Loader.actions;
export default Loader.reducer;
