import './Home.scss'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useEffect, useRef, useState } from 'react'
import heroBgImg from '@assets/media/images/hero-bg2.png'
import pizzaFig from '@assets/media/images/hero-pizza-img2.png'
import tesimonialFramepic from '@assets/media/images/tesimonial-figure2.png'
import star from '@assets/media/images/icon-star.svg'
import quotes from '@assets/media/images/quotes.svg'
import { motion, useScroll, useTransform } from 'framer-motion'
import decorImgLeftTop from '@assets/media/images/decor-bg-left-top.svg'
import decorImgLeftBottom from '@assets/media/images/decor-bg-left-bottom.svg'
import decorImgRightTop from '@assets/media/images/decor-bg-right-top.svg'
import decorImgRightBottom from '@assets/media/images/decor-bg-right-bottom.svg'
import tomatoDecorImg from '@assets/media/images/tomato.png'
import bigChilliDecorImg from '@assets/media/images/chilli.png'
import chilliLeftDecorImg from '@assets/media/images/red-chilli-2.png'
import chilliRightDecorImg from '@assets/media/images/chilli-1.png'
import yellowChilliRightDecorImg from '@assets/media/images/chilli-yellow.png'
import leafRightDecorImg from '@assets/media/images/leaf.png'
import redChilliRightDecorImg from '@assets/media/images/chilli-red.png'
import config from '@/config'
import {
  getContent,
  getReviews,
  getSpecialRecipes
} from '@/services/CommonService'
import SlickArrowRight from '../slick-slider/rightArrow'
import SlickArrowLeft from '../slick-slider/leftArrow'
import { Link } from 'react-router-dom'
import BottomAnimation from '@/components/layout/FeatureBlock/BottomAnimation'
import blurImage from '@assets/media/images/blue-bckgrnd2.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks'
import MenuPopup from '../popup/Menu/Menu'
import { toggleMenu } from '@/store/Reducer/Event/EventReducer'
import { recipeType } from '@/@types/RecipeModels'

type EventType = {
  description: string
  images: string[]
}

function Home () {
  const restaurantId = localStorage.getItem('restaurant_id')
  const preFetch = useRef<Boolean>(false)
  const dis = useAppDispatch()
  const showMenu = useAppSelector(val => val.eventsData.menu)
  const [topSlider, setTopSlider] = useState([{ src: pizzaFig }])
  const [specialty, setSpecialty] = useState({ content: '' })
  const [specialRecipes, setSpecialRecipes] = useState<recipeType[]>([])
  const [specialRecipeData, setSpecialRecipeData] = useState<recipeType>()
  const [reviews, setReviews] = useState([])
  const [event, setEvent] = useState<EventType>({
    description: '',
    images: []
  })
  const hideShowMenu = () => {
    dis(toggleMenu({ menu: !showMenu }))
  }

  const width = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )
  const mobileView = width < 575 ? true : false

  useEffect(() => {
    if (!preFetch.current) {
      preFetch.current = true
      getEvent()
      getMainLoader()
      getSpecialty()
      getSpecialtyRecipes()
      getRatings()
    }
    // eslint-disable-next-line
  }, [mobileView])
  const settings = {
    dots: false,
    infinite: true,
    speed: 2300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          speed: 1200
        }
      }
    ]
  }
  const setting_multiple = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }
  const setting_testimonial = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          speed: 1500
        }
      }
    ]
  }
  const settingEvents = {
    dots: true,
    fade: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  }
  const { scrollY } = useScroll()
  const y1 = useTransform(scrollY, [500, 100], [200, 20])
  const y2 = useTransform(scrollY, [800, 100], [100, 0])
  const y3 = useTransform(scrollY, [700, 10], [100, 0])
  // const y4 = useTransform(scrollY, [400, 100], [50, 0])
  const y5 = useTransform(scrollY, [2000, 1200], [700, 0])

  const decorImgsLeft = [
    { img: tomatoDecorImg, y: y1, class: 'left-ex-decor-1' },
    { img: bigChilliDecorImg, y: y2, class: 'left-ex-decor-2' },
    { img: chilliLeftDecorImg, y: y3, class: 'left-ex-decor-3' }
  ]
  const decorImgsRight = [
    { img: chilliRightDecorImg, y: y1, class: 'right-ex-decor-1' },
    { img: yellowChilliRightDecorImg, y: y2, class: 'right-ex-decor-2' },
    { img: leafRightDecorImg, y: y3, class: 'right-ex-decor-3' },
    { img: redChilliRightDecorImg, y: y3, class: 'right-ex-decor-4' }
  ]

  const getMainLoader = () => {
    if (restaurantId) {
      getContent({
        page: 'home',
        slug: 'header',
        restaurant_id: restaurantId
      })
        .then((res: any) => {
          if (res?.data?.data.length) {
            setTopSlider(
              res?.data?.data.map((x: any) => ({
                src: x?.image ? `${config.web_content}${x?.image}` : ''
              }))
            )
          }
        })
        .catch(e => {
          // console.log(e)
        })
    }
  }

  const getSpecialty = async () => {
    try {
      if (restaurantId) {
        const res = await getContent({
          page: 'home',
          slug: 'specialities',
          restaurant_id: restaurantId
        })
        if (res?.data?.data.length) {
          setSpecialty(res?.data?.data.pop())
        }
      }
    } catch (e) {}
  }

  const getSpecialtyRecipes = async () => {
    try {
      if (restaurantId) {
        const res = await getSpecialRecipes(Number(restaurantId))
        if (res?.data?.data.length) {
          setSpecialRecipes(
            res?.data?.data.map((x: any) => ({
              ...x,
              image: x?.image ? `${config.recipes}${x?.image}` : ''
            }))
          )
        }
      }
    } catch (e) {}
  }

  const getEvent = async () => {
    try {
      if (restaurantId) {
        const content = await getContent({
          page: 'home',
          slug: 'event-content',
          restaurant_id: restaurantId
        })
        if (content?.data?.data.length) {
          setEvent(prev => ({
            ...prev,
            description: content?.data?.data[0]?.content
          }))
        }
        const eventImagesRes = await getContent({
          page: 'home',
          slug: 'events',
          restaurant_id: restaurantId
        })
        if (eventImagesRes?.data?.data.length) {
          setEvent(prev => ({
            ...prev,
            images: (data => {
              return data.map((x: any) => x?.image ? `${config.web_content}${x.image}` : '')
            })(eventImagesRes?.data?.data)
          }))
        }
      }
    } catch (e) {}
  }

  const getRatings = async () => {
    try {
      const res = await getReviews()
      if (res?.data?.data) {
        setReviews(res.data.data)
      }
    } catch (e) {
      // console.log(e)
    }
  }

  /***** Escape key click listener */
  useEffect(() => {
    function handleEscapeKey (event: KeyboardEvent) {
      if (event.code === 'Escape') {
        dis(toggleMenu({ menu: false }))
      }
    }

    document.addEventListener('keydown', handleEscapeKey)
    return () => document.removeEventListener('keydown', handleEscapeKey)
    // eslint-disable-next-line
  }, [])

  const printStars = (rating: number) => {
    const stars = []
    for (let i = 0; i < rating; i++) {
      stars.push(<img src={star} alt='star' key={'rating'+i} />)
    }
    return stars
  }
  return (
    <>
      <section className='main-hero'>
        <div className='hero-bg-img'>
          <img src={heroBgImg} alt='' />
        </div>
        <div className='decor-imgs decor-imgs-left'>
          <img src={decorImgLeftTop} alt='' className='doc-main-fig' />
          {decorImgsLeft && decorImgsLeft.length
            ? decorImgsLeft.map((element, idx) => (
                <motion.div
                  key={'decorLeft' + idx}
                  className={`decor-img-extra ${element.class}`}
                  style={{ y: element.y, x: 0 }}
                >
                  <img src={element.img} alt='' />
                </motion.div>
              ))
            : ''}
        </div>

        <div className='decor-imgs decor-imgs-right'>
          <img src={decorImgRightTop} alt='' className='doc-main-fig' />
          {decorImgsRight && decorImgsRight.length
            ? decorImgsRight.map((element, idx) => (
                <motion.div
                  key={'decorRight' + idx}
                  className={`decor-img-extra ${element.class}`}
                  style={{ y: element.y, x: 0 }}
                >
                  <img src={element.img} alt='' />
                </motion.div>
              ))
            : ''}
        </div>
        <div className='hero-slider hero-slider--home'>
          <div className='hero-slider__items'>
            {topSlider && topSlider.length > 0 && (
              <>
                <Slider {...settings}>
                  {topSlider.map((data, key) => (
                    <div className='hero-slider__item' key={`header-${key}`}>
                      <div className='hero-slider__item__text'>
                        <figure>
                          <LazyLoadImage
                            alt={'pizzaFig'}
                            effect='blur'
                            src={data.src}
                            placeholderSrc={blurImage}
                          />
                          {/* <img src={data.src} alt='pizzaFig' /> */}
                        </figure>
                      </div>
                    </div>
                  ))}
                </Slider>
              </>
            )}
          </div>
        </div>
      </section>
      <section className='special-bloack-and-happy-customers'>
        <motion.div
          className='decor-imgs decor-imgs-left'
          style={{ y: y5, x: 0 }}
        >
          <img src={decorImgLeftBottom} alt='' />
        </motion.div>
        <motion.div
          className='decor-imgs decor-imgs-right'
          style={{ y: y5, x: 0 }}
        >
          <img src={decorImgRightBottom} alt='' />
        </motion.div>
        {/**
         *
         * Special Recipes
         *
         */}
        <div className='our-specialites-block'>
          <div className='cs-container'>
            <div className='common-header-ui text-center'>
              <h3>
                OUR <span>SPECIALITIES</span>
              </h3>
              <p dangerouslySetInnerHTML={{ __html: specialty?.content }}></p>
            </div>
            <div className='slider-product-pizza'>
              {specialRecipes && specialRecipes.length > 0 && (
                <>
                  <Slider {...setting_multiple}>
                    {specialRecipes.map((r: any, k) => (
                      <div
                        className='product-item'
                        onClick={() => {
                          hideShowMenu()
                          setSpecialRecipeData(r)
                        }}
                        key={'special' + r?.id}
                      >
                        <figure>
                          {/* <img src={r.image} alt="pizza1" /> */}
                          <LazyLoadImage
                            alt={r.image}
                            effect='blur'
                            src={r.image}
                            className={'img-pizza-mask'}
                            placeholderSrc={blurImage}
                          />
                        </figure>
                        <div className='product-disc'>
                          <h4>{r.name}</h4>
                          {/* {r.description.length <= 70 ? (
                            <p>{r.description} </p>
                          ) : (
                            <p>
                              {r.description.slice(0, 70)}...{' '}
                              <i className='cs-icon icon-book tooltip-icon'>
                                <span>{r.description}</span>
                              </i>{' '}
                            </p>
                          )} */}
                          {r.description ? <p className='product-disc__text'>{r.description}</p> : ''}
                          <strong>{r.price}</strong>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </>
              )}
            </div>
          </div>
        </div>
        {/**
         *
         * Ratings & Reviews
         *
         */}
        <div className='our-happy-customer'>
          <div className='cs-container'>
            <div className='common-header-ui text-center'>
              <h3>
                Happy <span>Customers</span>
              </h3>
            </div>
            <div className='testimonial-block'>
              <div className='testimonial-frame'>
                <div className='frame-head'>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <figure>
                  {/* <LazyLoadImage
                    alt={'pizza1'}
                    effect='blur'
                    src={tesimonialFramepic}
                    className={''}
                    placeholderSrc={blurImage}
                  /> */}
                  <img loading='lazy' src={tesimonialFramepic} alt='pizza1' />
                </figure>
              </div>
              <div className='testimonial-slider'>
                <div className='quotes'>
                  <img src={quotes} alt='pizza1' />
                </div>
                <Slider {...setting_testimonial}>
                  {reviews && reviews.length
                    ? reviews.map((element: any, idx: number) => (
                        <div className='testimonial-items' key={element?.id}>
                          <div className='star-rate'>
                            {printStars(element?.rating)}
                          </div>
                          <h5>{element?.message}</h5>
                          <p>{`${element?.first_name} ${element?.last_name}`}</p>
                        </div>
                      ))
                    : ''}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/**
       *
       * Event Block
       *
       */}
      <section className='events-block'>
        <div className='cs-container'>
          <div className='cs-row align-center'>
            <div className='cell-6'>
              <div className='left-event-figure'>
                <div className='bg-image-event'></div>
                <div className='bg-image-event with-mask'>
                  {event && event.images.length > 0 && (
                    <Slider {...settingEvents}>
                      {event.images.map((src, k) => (
                        <LazyLoadImage
                          key={'event' + k}
                          alt={'imgEvent'}
                          effect='blur'
                          src={src}
                          className={''}
                          placeholderSrc={blurImage}
                        />
                        // <img src={src} alt='imgEvent' key={'event' + k} />
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
            <div className='cell-6'>
              <div className='common-header-ui right-event-figure'>
                <h3>
                  BigM Nightlife <span>and Events</span>
                </h3>
                <p dangerouslySetInnerHTML={{ __html: event?.description }}></p>
                <Link to={'/contact-us'} state={{ from: 'event' }}>
                  <button className='main-nav__list__item btn btn--pri'>
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomAnimation></BottomAnimation>
      {showMenu && (
        <MenuPopup
          data={specialRecipeData}
          close={hideShowMenu}
          type={'special'}
        />
      )}
    </>
  )
}
export default Home
