import Popup from '../Popup'
import { PopupType } from '@/@types/PopupModel'
import { useAppDispatch } from '@/store/StoreHooks'
import { toggleMenu } from '@/store/Reducer/Event/EventReducer'
import { useState } from 'react'
import config from '@/config'

interface voidFunc {
  (): void
}
interface menuPopupProps {
  data: any
  close: voidFunc
  type?: string
}

function MenuPopup ({ data, close, type }: menuPopupProps) {
  const dis = useAppDispatch()
  const hideShow = (): void => {
    dis(toggleMenu({ [PopupType.MENU]: false }))
  }
  
  const [readMore, setReadMore] = useState(false)
  return (
    <Popup type={PopupType.MENU} close={hideShow}>
      <div className='popup-contents'>
        <div className='product-card inner-scroll'>
          <div className='product-card__close' onClick={() => close()}>
            <i className='cs-icon icon-close'></i>
          </div>
          <div className='highlight-box-container'>
            {
              data?.is_special || (data?.is_special && data?.is_popular) ?
                <div className='highlight-box special'>
                  <p>
                    <span>Special</span>
                  </p>
                </div>
                : ''
            }
            {
              data?.is_popular && !data?.is_special ? 
                <div className='highlight-box'>
                  <p>
                    <span>Most Popular</span>
                  </p>
                </div> 
                : ''
            }
          </div>
          <picture className='product-card__image'>
            <img
              src={
                type === 'special'
                  ? `${data?.image}`
                  : `${config.recipes}${data?.image}`
              }
              alt=''
            />
          </picture>
          <div className='product-card__body'>
            <h3 className='product-card__body__title'>{data?.name}</h3>
            <p className='product-card__body__desc'>
              {data?.description?.length >= 1000 ? (
                <span>
                  {readMore
                    ? data?.description
                    : data?.description.slice(0, 1000) + '...'}{' '}
                  <i
                    className='cs-icon icon-book'
                    onClick={() => setReadMore(!readMore)}
                  ></i>
                </span>
              ) : (
                <p dangerouslySetInnerHTML={{__html: data?.description}}></p>
              )}
            </p>
            <h4 className='product-card__body__price'>{data?.price} {data?.additional_item && data?.additional_price ?<span className='product-card-price__info'>({`${data?.additional_item}`} <b className='product-add-price'>${`${data?.additional_price}`}</b>)*</span> : ''}</h4>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default MenuPopup
