import { useLayoutEffect, useState, lazy } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { Suspense } from 'react'
import Header from '../layout/Header/Header'
import Loader from '../common/Loader/Loader'
import { useAppSelector } from '@/store/StoreHooks'
import RouteGuard from './RouteGuard'
import Home from '@components/Home/Home';
const Menu = lazy(() => import('@components/Menu/Menu'))
const ContactUs = lazy(() => import('../contact-us/ContactUs'))
const Footer = lazy(() => import('../layout/Footer/Footer'))
const Location = lazy(() => import('../location/Location'))
const AboutUs = lazy(() => import('../about/AboutUs'))
const LocationPopup = lazy(() => import('../popup/Menu/Locations'))
const Catering = lazy(() => import('../Catering/Catering'))

const Wrapper = (props: any) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return props.children
}

function Pages () {
  const restaurantId: number = Number(localStorage.getItem('restaurant_id'))
  const loader = useAppSelector(val => val.loader)
  const routes = [
    {
      path: '/',
      component: <Home />
    },
    {
      path: '/location',
      component: <Location />
    },
    {
      path: '/contact-us',
      component: <ContactUs />
    },
    {
      path: '/menu',
      component: <Menu />
    },
    {
      path: '/about-us',
      component: <AboutUs />
    },
    {
      path: '/catering',
      component: <Catering />
    }
  ]
  const [showPopup, setShowPopup] = useState(restaurantId ? false : true)

  const locationCallback = (cb: boolean) => {
    setShowPopup(cb)
  }
  return (
    <>
      <Suspense fallback={<Loader load={loader}></Loader>}>
        <BrowserRouter>
          <Wrapper>
            <Header />

            {!showPopup ? (
              <>
                <Routes>
                  {routes.map((route: any, idx: number) => (
                    <Route
                    key={idx}
                      path={route?.path}
                      element={
                        <RouteGuard
                          component={route?.component}
                          callback={locationCallback}
                        />
                      }
                    />
                  ))}
                </Routes>
                <Footer />
              </>
            ) : (
              <LocationPopup callback={locationCallback} />
            )}
          </Wrapper>
        </BrowserRouter>
      </Suspense>
    </>
  )
}
export default Pages
