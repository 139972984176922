import { ReactElement } from "react";
export enum PopupType {
  MENU = "menu",
  LOCATION = "location",
}
interface SearchFunc {
  (): void;
}
export interface propsType {
  children: ReactElement;
  type: PopupType;
  close?: SearchFunc,
  open?: boolean
}

export interface MenuProps {
  type: 'menu';
}
