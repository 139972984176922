import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './Reducer/User/UserReducer';
import LoaderReducer from './Reducer/Loader/LoaderReducer';
import EventReducer from './Reducer/Event/EventReducer';
import MenuReducer from './Reducer/Menu/MenuReducer';
import SelectedRestaurantReducer from './Reducer/SelectedRestaurant/SelectedRestaurantReducer';
const store = configureStore({
    reducer: {
        userDetail: UserReducer,
        loader: LoaderReducer,
        eventsData: EventReducer,
        menuData: MenuReducer,
        selectedRestaurant: SelectedRestaurantReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export default store;