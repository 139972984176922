import axios from "axios";
import { toast } from "react-toast";
import { setLoader } from "@/store/Reducer/Loader/LoaderReducer";
import store from "@/store/store";

const axiosApi = axios.create();

axiosApi.interceptors.request.use(
    (req: any) => {
        const exemptedRequest = [``]; 
        if(exemptedRequest.includes(req.url)){            
            store.dispatch(setLoader(false));
        } else {
            store.dispatch(setLoader(true));
        }
        req.headers.Authorization = `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : ''}`;
        return req;
    }
);

/****** Intercepts Response ********/
axiosApi.interceptors.response.use(function (response:any) {
    store.dispatch(setLoader(false));
    if (response && response.data && response.data.status === 'success') {
        if (response.data.message) {
            toast.hideAll();
            toast.success(response.data.message);
        }
    }
    return response;
}, function (error: any) {
    store.dispatch(setLoader(false));
    if (error && error.response && error.response.status) {
        if (error.response.status === 401) {
            toast.hideAll();
            toast.error('Unauthorized');
            localStorage.removeItem('token')
            window.location.href = '/';
        } else {
            if (error.response.data && error.response.data.message) {
                toast.hideAll();
                toast.error(error.response.data.message);
            } else {
                toast.hideAll();
                toast.error(error.response.data.message);
            }
        }
    }

    // do what you want to do when error happens
    return Promise.reject(error);
});

export default axiosApi;