import config from "@/config";
import { getNotifications } from "@/services/CommonService";
import { useEffect, useState } from "react";
import moment from "moment";

function PopupBanner(){
    const restaurantId: number = Number(localStorage.getItem('restaurant_id'))
    const [popupData, setPopupData] = useState<any>('');
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const getNotis = async (id: number) => {
        const res = await getNotifications(id);
        if (res?.data?.data && res.data.data.length) {
            const resp = res.data.data
            const popupToShow = (resp?.filter((el: any) => el.type === 'popup')).reduce((a: any, b: any) => a.start_date < b.start_date ? a : b)
            setPopupData(popupToShow);
        }
    }
    useEffect(() => {
        if (restaurantId) {
            getNotis(restaurantId);
        }
    }, [restaurantId])

    useEffect(() => {
        if (popupData && popupData.image) {
            const popupId: any = localStorage.getItem('popupId')
            const expires: any = localStorage.getItem('expires')
            const tommorow = new Date()
            tommorow.setDate(tommorow.getDate()+1)
            tommorow.setHours(0,0,0,0)
            if (popupId && expires) {
                if (popupData.id !== +popupId) {
                    localStorage.setItem('popupId', popupData.id)
                    localStorage.setItem('expires', tommorow.toString())
                    setTimeout(() => { setShowPopup(true) }, 2500)
                } else if (popupData.id === +popupId && moment(expires).format() < moment().format()) {
                    localStorage.setItem('expires', tommorow.toString())
                    setTimeout(() => { setShowPopup(true) }, 2500)
                }
            } else {
                localStorage.setItem('popupId', popupData.id)
                localStorage.setItem('expires', tommorow.toString())
                setTimeout(() => { setShowPopup(true) }, 2500)
            }
        }
    }, [popupData])

    return(
        <>
        <div className={`popup-banner ${showPopup ? 'show' : ''}`}>
            <div className="popup-banner__container">
                <div className="popup-banner__overlay"></div>
                <div className="popup-banner__contents">
                    <span className="close-toggler" onClick={() => setShowPopup(false)}>
                        <i className="cs-icon icon-close"></i>
                    </span>
                    <div className="popup-banner__container__media with-popup-mask">
                        <img className="" src={popupData?.image ? `${config.web_content}${popupData?.image}` : ''} alt="" />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default PopupBanner;