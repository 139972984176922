import arrowLeftIcon from "@assets/media/images/icon-arrow-left.svg";
const SlickArrowLeft = ({ ...props }) => {
  return (
    <div onClick={props.onClick} className={`cs-slick-arrow arrow-left ${props.onClick ? '' : 'disabled'}`}>
      <img src={arrowLeftIcon} alt="" />
    </div>
  );
};

export default SlickArrowLeft;
