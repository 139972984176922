import mainLogo from "@assets/media/images/logo.png";
import { useEffect } from "react";

function PreLoader (){
    useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('hide-scroll');
        document.body.classList.remove('loaded');
        window.addEventListener('load', function () {
          document.body.classList.add('loaded');
          document.getElementsByTagName('html')[0].classList.remove('hide-scroll');
        })
      }, []);
    return(
        <>
            <div className="preloader" id="preloader">
                <span>
                <img src={mainLogo} alt="" />
                </span>
            </div>
        </>
    )
}
export default PreLoader;